/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Nav from "./Nav"
import Footer from "./Footer"
import { useStaticQuery, graphql } from "gatsby"

import ThemeTopLayout from "../../node_modules/gatsby-theme-material-ui-top-layout/src/components/top-layout";

const Layout = ({ children, theme }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
console.log(data.site.siteMetadata.title)
  return (
    <>
    <ThemeTopLayout
     theme={theme}>
      
      <Nav />
      <main>{children}</main>
      <Footer />
    </ThemeTopLayout>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
