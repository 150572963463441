import React from 'react'
import { Link } from "gatsby"
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons"

import logo from '../images/logo-footer-03.svg';
import Tawk from './Tawk';
export default class Footer extends React.Component{
    render(){
        return (
            <div>
                <Tawk />
                 <footer className="page-footer">
                    <div className="container">
                        <br />

                        <div className="row">
                            <div className="col l3 s12">
                                <div className="center">
                                    <a id="logo-container" href="/" className="brand-logo"> 
                                        <img className="footer-logo mt-5" 
                                            src={logo} 
                                            alt="The Painted Bunny" 
                                        /> 
                                    </a>
                                    <div className="footer-social text-l-right text-sm-left-">
                                        <span className="mr-15 hide-on-small-only">CONNECT WITH US</span>
                                        <ul className="social-icon">
                                            <li><a rel="noreferrer" href="https://www.linkedin.com/company/savanteconsulting" target="_blank"><FontAwesomeIcon aria-label="Mute volume" icon={faLinkedin} /></a></li>
                                            <li><a rel="noreferrer" href="https://www.facebook.com/savanteconsultingofficial" target="_blank"><FontAwesomeIcon aria-label="Mute volume" icon={faFacebook} /></a></li>
                                            <li><a rel="noreferrer" href="https://twitter.com/savanteconsult" target="_blank"><FontAwesomeIcon aria-label="Mute volume" icon={faTwitter} /></a></li>
                                            <li><a rel="noreferrer" href="https://www.instagram.com/savanteconsulting/" target="_blank"><FontAwesomeIcon aria-label="Mute volume" icon={faInstagram} /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col l3 s12">
                                <h5 className="title">About</h5>
                                <ul className="footer-links">
                                <li><Link to="/contact-us">Contact us</Link></li>
                                    <li><Link to="/our-clients">Our Clients</Link></li>
                                    <li><Link to="/Articles">Articles</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/other/sitemap">Sitemap</Link></li>
                                </ul>
                            </div>
                            <div className="col l3 s12">
                                <h5 className="title">Services</h5>
                                <ul className="footer-links">
                                    <li><Link to="/services/our-services">Our Services</Link></li>
                                    <li><Link to="/services/regulatory-affairs">Regulatory Affairs</Link></li>
                                    <li><Link to="/services/clinical-trails">Clinical Trials</Link></li>
                                    <li><Link to="/services/pharmacovigilance">Pharmacovigilance</Link></li>
                                    <li><Link to="/about-us">About Us</Link></li>
                                </ul>
                            </div>
                            <div className="col l3 s12">

                                <h5 className="title">Contact Us</h5>
                                <ul className="footer-links">
                                    <li><a rel="noreferrer" target="_blank" 
                                        href="https://www.google.ru/maps/place/Savante+Consulting+Com/@6.453666,3.3873153,17z/data=!3m1!4b1!4m5!3m4!1s0x103b8b568f3f5fc1:0xb2c59461ac52d3b7!8m2!3d6.453666!4d3.389504?hl=en">
                                            7th Floor Broad Street, NCR Building Lagos, Lagos Island, Lagos, Nigeria</a></li>
                                    <li>Office Line 1: <a href="tel:23412916035">+234 12 91 6035</a></li>
                                    <li>Office Line 2: <a href="tel:2348052448547">+234 805 244 8547</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="container center">
                            {"Savante Consulting Limited  © "+new Date().getFullYear()} Designed by <a rel="noreferrer" target="_blank" href="http://next.neebak.com/">Neebak Next </a>
                           <br />
                           <Link to="/other/sitemap">Sitemap -</Link>
                           <Link to="/other/privacy-policy"> Privacy Policy -</Link>
                           <a rel="noreferrer" target="_blank"  href="https://savanteconsulting.com/webmail/"> Mail</a>
                           <a rel="noreferrer" target="_blank"  href="https://savanteconsulting.com/cpanel/"> Login</a>
                        </div>
                    </div>
                </footer>


            </div>
        )
    }
}