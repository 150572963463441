import React, { useEffect } from "react";

function Tawk(){

     useEffect(() => {
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement("script"),
            s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = "https://embed.tawk.to/5d0c196753d10a56bd7b1d91/default";
          s1.charset = "UTF-8";
          s1.setAttribute("crossorigin", "*");
          s0.parentNode.insertBefore(s1, s0);
        })();
        console.log(Tawk_LoadStart );

      }, []);

      return (
        <div>
         
        </div>
      );
}
export default Tawk