import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'gatsby';
import navlogo from '../images/logo.svg'

import './Nav.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 15
  },
  menuButton: {
    marginRight: theme.spacing(5),
  },
  Toolbar: {
    width: '70%',
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const menuLinks =[
  {
    text:'Home',
    link: '/',
    key: 1
  },
  {
    text:'Contact Us',
    link: '/contact-us',
    key: 2
  },
  {
    text:'Our Services',
    link: '/services/our-services',
    key: 3
  },
  {
    text:'Regulatory Affairs',
    link: '/services/regulatory-affairs',
    key: 3
  },
  {
    text:'Clinical Trials',
    link: '/services/clinical-trails',
    key: 3
  },
  {
    text:'Pharmacovigilance',
    link: '/services/pharmacovigilance',
    key: 3
  },
  {
    text:'Our Clients',
    link: '/our-clients/#our-clients',
    key: 4
  },
  {
    text:'Articles',
    link: '/Articles',
    key: 5
  },
  {
    text:'About Us',
    link: '/about-us',
    key: 5
  },
  {
    text:'Careers',
    link: '/careers',
    key: 6
  }
]

function TemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };
  
    const list = (anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {menuLinks.map((link) => (
            <ListItem button key={link.key}>
              <Link to={link.link}> {link.text}  </Link>
            </ListItem>
          ))}
        </List>
      </div>
    );
  
    return (
      <div>


        <IconButton 
            edge="start" 
            className="hide-on-med-and-up mr-0" 
            color="inherit" aria-label="menu"
            onClick={toggleDrawer('left', true)}
            
        >
            <MenuIcon  />

        </IconButton>
        <Drawer anchor='left' open={state['left']} onClose={toggleDrawer('left', false)}>
              {list('left')}
        </Drawer>
      </div>
    );
  }
  
 function DropDownMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button className="hide-on-small-only white-text" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          Services
        </Button>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}

            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
          <MenuItem onClick={handleClose}><Link to="/services/our-services" >Our Services</Link></MenuItem>
          <MenuItem onClick={handleClose}><Link to="/services/regulatory-affairs" >Regulatory Affairs</Link></MenuItem>
          <MenuItem onClick={handleClose}><Link to="/services/clinical-trails" >Clinical Trials</Link></MenuItem>
          <MenuItem onClick={handleClose}><Link to="/services/pharmacovigilance" >Pharmacovigilance</Link></MenuItem>
        </Menu>
      </div>
    );
  }

export default function Nav() {
  const classes = useStyles();
  

  return (
    <div className="">
      <AppBar>
        <Toolbar disableGutters="true"  className="fixed container">

          <TemporaryDrawer />

          <Link className="brand-logo" to="/"> <img className="nav-logo" src={navlogo} alt="Savante Consulting Limited" /></Link>


          <Typography variant="h6" className={classes.title}>
          
          </Typography>

          <DropDownMenu />
          <Link  className="nav-button hide-on-small-only" activeClassName="active" to="/our-clients/#our-clients" >Our Clients</Link>
          <Link  className="nav-button hide-on-small-only" activeClassName="active" to="/Articles" >Articles</Link>
          <Link  className="nav-button hide-on-small-only" activeClassName="active" to="/about-us" >About Us</Link>
          <Link  className="nav-button hide-on-small-only" activeClassName="active" to="/contact-us" >Contact Us</Link>

        </Toolbar>
      </AppBar>
    </div>
  );
}
